import React, { useState, useEffect } from "react";

import styled, { css } from "styled-components";

import {Wrapper} from "../Wrapper"
import Blog from '../Blog/Blog'
import PostImage from "../../images/post.jpg";
import SampleImage from "../../images/post1.jpg";

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .4;
  position: absolute;
  z-index: 1;
`;

const Div = styled.div`
  position: relative;
  height: 80vh;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  &::before {
    background: #fff;
    top: 0;
    left: 0;
    width: 60%;
    height: 100%;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    content: "";
    position: absolute;
    z-index: 100;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    z-index: 2;
    transition-property: all;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: 100;
    border: 50px solid #fff;
    border-top: 0;
    border-left: 0;
    top: -100px;
    right: -80px;
    bottom: -80px;
    left: -80px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    transition-property: all;
  }

  ${props =>
    props.animate &&
    css`
      &::before {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
        transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        z-index: 2;
      }
      &::after {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0);
      }
    `}
`

const Img = styled.img`
  max-width: 100%;
  width: 100%;
`

const P = styled.p`
  font-size: 60px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.1;
  color: #fff;
  ${props =>
    props.animate &&
    css`
    color: #000;
    `}
`

const TextWrapper = styled.div`
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  text-align: right;
  width: 50%;
  position: absolute;
  top: 50%;
  right: 20%;
  transform: translate(0, -50%);
  z-index: 2;
  ${props =>
    props.animate &&
    css`
      transform: translate(-50%, -50%);
    `}
`

const MetaWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  position: absolute;
  right: 0;
  transform: translate(0, 350%);
  opacity: 0;
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: -webkit-transform;
  transition-property: all;
  ${props =>
    props.animate &&
    css`
      opacity: 1;
      -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
      transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-property: -webkit-transform;
      transition-property: all;
      transform: translate(0, 0);
    `}
`

const Meta = styled.p`
  display: inline-block;
  ${props =>
    props.news &&
    css`
      background: #000;
      color: #fff;
      padding: 5px 15px;
      margin-right: 10px;
      font-size: 18px;
    `}
`

const ContentWrapper = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 0;
  background-color: #fff;
  p {
    font-size: 16px;
    margin-top: 20px;
    line-height: 1.5;
    color: #545454;
    &:first-of-type {
      margin-top: 0;
    }
  }
  h1 {
    font-size: 32px;
    margin: 30px 0 20px;
  }
  h2 {
    font-size: 28px;
    margin: 30px 0 20px;
  }
  h3 {
    font-size: 24px;
    margin: 30px 0 20px;
  }
  h4 {
    font-size: 20px;
    margin: 30px 0 20px;
  }
  h5 {
    font-size: 16px;
    margin: 30px 0 20px;
  }
  h6 {
    font-size: 12px;
    margin: 30px 0 20px;
  }
  ul {
    padding-left: 20px;
    list-style: initial;
    li {
      margin: 20px 0 0;
      font-size: 16px;
    }
  }
  blockquote {
    padding: 40px 20px;
    margin: 30px 0 20px;
    font-size: 20px;
    line-height: 1.5;
    position: relative;
    color: #404040;
    &::before {
      content: "";
      height: 80%;
      width: 4px;
      background-color: #e3552e;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  img {
      max-width: 100%;
      display: block;
  }
`

const FeaturedImage = () => {
  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY >= 1
      if (scrollCheck !== scrolled) {
        setScrolled(!scrolled)
      }
    })
  }, [scrolled])
  return (
    <>
      <Div animate={scrolled ? true : ""}>
        <Overlay/>
        <Img src={PostImage} alt="" animate={scrolled ? true : ""} />
        <TextWrapper animate={scrolled ? true : ""}>
          <P animate={scrolled ? true : ""}>
            Facing a challenge is kind of a turn-on for an easy rider
          </P>
          <MetaWrapper animate={scrolled ? true : ""}>
            <Meta news>News</Meta>
            <Meta date>July 19, 2018</Meta>
          </MetaWrapper>
        </TextWrapper>
      </Div>
      <ContentWrapper>
        <p>
          Nam ut rutrum ex, venenatis sollicitudin urna. Aliquam erat volutpat.
          Integer eu ipsum sem. Ut bibendum lacus vestibulum maximus suscipit.
          Quisque vitae nibh iaculis neque blandit euismod. Maecenas sit amet
          purus eget ipsum elementum venenatis. Aenean maximus urna magna, quis
          rutrum mi semper non. Cras rhoncus elit non arcu hendrerit rhoncus.
          Pellentesque dignissim volutpat orci at interdum. In id ipsum
          volutpat.
        </p>

        <p>
          Nam ut rutrum ex, venenatis sollicitudin urna. Aliquam erat volutpat.
          Integer eu ipsum sem. Ut bibendum lacus vestibulum maximus suscipit.
          Quisque vitae nibh iaculis neque blandit euismod. Maecenas sit amet
          purus eget ipsum elementum venenatis. Aenean maximus urna magna, quis
          rutrum mi semper non. Cras rhoncus elit non arcu hendrerit rhoncus.
          Pellentesque dignissim volutpat orci at interdum. In id ipsum
          volutpat.
        </p>

        <p>
          Nam ut rutrum ex, venenatis sollicitudin urna. Aliquam erat volutpat.
          Integer eu ipsum sem. Ut bibendum lacus vestibulum maximus suscipit.
          Quisque vitae nibh iaculis neque blandit euismod. Maecenas sit amet
          purus eget ipsum elementum venenatis. Aenean maximus urna magna, quis
          rutrum mi semper non. Cras rhoncus elit non arcu hendrerit rhoncus.
          Pellentesque dignissim volutpat orci at interdum. In id ipsum
          volutpat.
        </p>

        <p>
          Nam ut rutrum ex, venenatis sollicitudin urna. Aliquam erat volutpat.
          Integer eu ipsum sem. Ut bibendum lacus vestibulum maximus suscipit.
          Quisque vitae nibh iaculis neque blandit euismod. Maecenas sit amet
          purus eget ipsum elementum venenatis. Aenean maximus urna magna, quis
          rutrum mi semper non. Cras rhoncus elit non arcu hendrerit rhoncus.
          Pellentesque dignissim volutpat orci at interdum. In id ipsum
          volutpat.
        </p>

        <h1>H1</h1>
        <h2>H2</h2>
        <h3>H3</h3>
        <h4>H4</h4>
        <h5>H5</h5>
        <h6>H6</h6>
        <ul>
          <li>list 1</li>
          <li>list 2</li>
          <li>list 3</li>
        </ul>
        <blockquote>
          Nam ut rutrum ex, venenatis sollicitudin urna. Aliquam erat volutpat.
          Integer eu ipsum sem. Ut bibendum lacus vestibulum maximus suscipit.
        </blockquote>
        <img src={SampleImage} alt=""/>
        <p>
          Nam ut rutrum ex, venenatis sollicitudin urna. Aliquam erat volutpat.
          Integer eu ipsum sem. Ut bibendum lacus vestibulum maximus suscipit.
          Quisque vitae nibh iaculis neque blandit euismod. Maecenas sit amet
          purus eget ipsum elementum venenatis. Aenean maximus urna magna, quis
          rutrum mi semper non. Cras rhoncus elit non arcu hendrerit rhoncus.
          Pellentesque dignissim volutpat orci at interdum. In id ipsum
          volutpat.
        </p>
      </ContentWrapper>
      <Wrapper>
        <Blog />
        </Wrapper>
    </>
  )
}

export default FeaturedImage
