import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import FeaturedImage from "../components/FeaturedImage";

const SinglePage = () => (
  <Layout>
    <SEO title="Single Page" />
    <FeaturedImage/>
  </Layout>
)

export default SinglePage;
